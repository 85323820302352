import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { employeeId: String }; // Change to String

  show(event) {
    event.preventDefault();

    // Retrieve the value
    const employeeId = this.employeeIdValue; 

    if (!employeeId) {
      console.error("Employee ID not found");
      return;
    }

    console.log(`Fetching modal content for Employee ID: ${employeeId}`);

    // Fetch the modal content
    fetch(`/employees/${employeeId}/last_application_modal`, {
      headers: { Accept: "text/html" },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Failed to fetch modal content");
        }
      })
      .then((html) => {
        console.log("Modal content fetched successfully");
        document.querySelector("#dynamic-modal-area").innerHTML = html;

        const modalElement = document.querySelector(`#lastApplicationModal-${employeeId}`);
        if (modalElement) {
          const bootstrapModal = new bootstrap.Modal(modalElement);
          bootstrapModal.show();
        } else {
          console.error(`Modal with ID lastApplicationModal-${employeeId} not found`);
        }
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
}
